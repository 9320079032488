.filtersPanel_filterContainer-enter {
  opacity: 0;
}
.filtersPanel_filterContainer-enter-active {
  opacity: 1;
  transition: opacity 700ms ease-out, max-width 200ms 400ms;
}

.filtersPanel_filterContainer-exit {
  opacity: 1;
  max-width: 800px;
  max-height: 30px;
}

.filtersPanel_filterContainer-exit-active {
  opacity: 0;
  max-height: 30px;
  max-width: 0px !important;
  margin-left: 0 !important;
  transition: opacity 400ms ease-in, max-width 300ms 200ms ease-in, margin-left 300ms 200ms ease-in;
}
