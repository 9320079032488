.BadgeGroups {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;

  :global(.justui_badge) {
    height: fit-content;
  }

  .disabled {
    background-color: var(--gray-200) !important;
    opacity: 0.7;
    text-decoration: line-through;
  }
}
