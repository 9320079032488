@import '~@just-ai/just-ui/scss/basic/custom/functions';
@import '~bootstrap-scss/functions';
@import '~@just-ai/just-ui/scss/basic/custom/variables';
@import '~bootstrap-scss/variables';

.webkitScroll {
  &::-webkit-scrollbar {
    position: relative;
    width: 16px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    min-height: 10px;
    background-clip: padding-box;
    border-right: 5px solid transparent;
    border-top: 0 solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 5px solid transparent;
    border-radius: 6px;
    background-color: $greyblue;
    transition: background-color 0.3s ease-in-out;
    &:hover {
      background-color: var(--primary-400);
    }
  }

  &::-webkit-scrollbar-button {
    display: block;
    height: 0;
  }
}

.FilterModal {
  position: relative;
  display: inline-flex;
  align-items: center;

  .popper-wrapper {
    position: absolute;
    left: 0;
    top: 35px;
    z-index: 2;
    min-width: 300px;
    box-shadow: 0px 0px 1px 0px rgba(17, 60, 92, 0.06), 0px 6px 12px 0px rgba(12, 43, 66, 0.16),
      0px 0px 2px 0px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    background: var(--white);
    &.popper-wrapper-right {
      left: initial;
      right: 0;
    }

    overflow: auto;

    @extend .webkitScroll;
  }

  button,
  .fulfilled-pseudobutton {
  }
  .fulfilled-pseudobutton {
    display: inline-flex;
    background: var(--primary-200);
    border: 1px solid var(--primary-100);
    letter-spacing: 0.02em;
    font-size: 0.75rem;
    line-height: 1.66667;
    border-radius: 0.25rem;
    color: var(--gray-800);

    .separator:hover {
      background-color: var(--black-4per);
    }

    .remove:hover {
      background-color: var(--black-4per);
    }

    .filter-label {
      color: var(--primary-600);
    }

    &.showModal {
      .separator {
        color: var(--gray-800);
        background-color: var(--primary-300);
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    svg {
      cursor: pointer;
    }

    .separator {
      display: inline-block;
      padding: 0.25rem 0.5rem;
    }

    .remove {
      display: flex;
      padding: 0.4rem 0.5rem;
      align-self: center;
      span {
        display: flex;
      }
    }
  }

  .searchInput {
    min-width: 285px;
    padding-right: 36px;

    + span {
      position: absolute;
      top: 7px;
      right: 7px;
      font-size: 0.75rem;
      line-height: 1rem;
      color: var(--primary);
    }
  }

  &.select {
    min-width: 285px;
  }

  .nameSearchInput {
    width: 100%;

    .ReactSelectPrefix__control {
      height: 30px;
    }

    .ReactSelectPrefix__indicators {
      display: none;
    }
    .ReactSelectPrefix__value-container {
      line-height: 18px;
    }

    .ReactSelectPrefix__menu-list {
      @extend .webkitScroll;
    }

    + span {
      position: absolute;
      top: 7px;
      right: 7px;
      font-size: 0.75rem;
      line-height: 1rem;
      color: var(--gray-500);

      &.colorEnter {
        color: var(--primary);
      }
    }
  }
}
