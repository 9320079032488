@import '~bootstrap-scss/functions';
@import '~bootstrap-scss/variables';

$checkbox-indicator-partial: escape-svg(
  str-replace(
    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$custom-control-indicator-checked-color}' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 341.333 341.333'><g><g><rect y='149.333' width='341.333' height='42.667'/></g></g></svg>"),
    '#',
    '%23'
  )
);
$checkbox-indicator-parent: escape-svg(
  str-replace(
    url("data:image/svg+xml,<svg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='3' cy='3' r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>"),
    '#',
    '%23'
  )
);

.tree-checkbox-wrapper {
  display: inline-flex;
  width: 100%;

  .custom-tree-control-input {
    top: 0.3125rem;
    z-index: 1;
    height: 0.875rem;
    opacity: 0;
  }

  &.custom-checkbox-sm {
    .custom-control-label {
      span.label-container {
        span.name {
          padding: 0;
          line-height: inherit;
        }
      }
    }
  }

  label {
    //color: #ffffff;
  }

  .custom-control-label {
    display: block;
    width: 100%;
    span.label-container {
      display: flex;
      align-items: center;

      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span.name {
        display: block;
        cursor: pointer;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: normal;
        padding: 4px 0;

        + .count {
          margin-left: 0.25rem;

          + .selectedChildCount {
            margin-left: 0.25rem;
          }
        }
        + .selectedChildCount {
          margin-left: 0.25rem;
        }
      }
    }

    .isDeleted {
      color: var(--gray-600);
    }
  }

  .customData {
    border-radius: 4px;
    background: var(--gray-200);
    padding: 0px 2px;
  }

  span.counterCount {
    display: inline-flex;
    font-size: 0.725rem;
    line-height: 1rem;
    letter-spacing: 0.02em;
    margin-right: 0.25rem;
    color: var(--secondary);
  }
  span.selectedChildCount {
    display: inline-flex;

    cursor: pointer;
    padding: 0 0.125rem;
    font-size: 0.725rem;
    line-height: 1rem;
    letter-spacing: 0.02em;
    color: var(--gray-600);
    max-height: 1rem;
    border-radius: 3px;
    justify-content: center;
    margin-right: 0.25rem;
  }
}

.tree-link {
  .tree-link__carret-style {
    display: flex;
  }

  .tree-link__leaf {
    //margin-bottom: 8px;
  }

  .tree-link__leaf-light {
    border-color: transparent;

    &:hover {
      background-color: #f2f2f2;
    }
  }
}

.custom-tree-control-input {
  &.PARTIAL {
    &:checked {
      ~ .custom-control-label {
        &::after,
        &:after {
          background-image: $checkbox-indicator-partial !important;
        }
      }
    }
  }
  &.PARENT {
    &:checked {
      ~ .custom-control-label {
        &::after,
        &:after {
          background-image: $checkbox-indicator-parent !important;
          background-size: 6px;
        }
      }
    }
  }
}
