.LimitsHeader {
  padding: 0.75rem;
  border-radius: 8px;
  background: var(--gray-50);
  margin-bottom: 1rem;
  display: flex;
  gap: 8px;
  align-items: center;

  .title {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--gray-900);
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.limitItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  background: white;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 0.875rem;
}

.productName {
  font-weight: 600;
  color: var(--gray-900);
}

.value {
  color: var(--gray-900);
}

.separator {
  color: var(--gray-400);
}

.overflow {
  color: var(--danger);
}
