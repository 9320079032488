@use "sass:math";

.CompositeFilter {
  min-width: 528px;

  .just-select-container {
    min-height: 30px;
    height: 30px;

    input {
      height: calc(1.66667em + .5rem);
      padding: .25rem .5rem;
      font-size: .75rem;
      line-height: 1.66667;
      border-radius: .2rem;
    }
  }

  .CompositeFilterItem_container {

  }

  .CompositeFilterItem {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 30px;
    margin-bottom: 10px;
    &.CompositeFilterItemRevert {
      grid-template-columns: 1fr 2fr 30px;
    }

    .btn-rect {
      padding: 0.375rem;
    }

    .caseSensitive {
      position: relative;
    }

    .btn-caseSensitive {
      position: absolute;
      top: 1px;
      right: 1px;
      box-shadow: none;

      &:active {
        box-shadow: none;
      }

      &.caseSensitive {
        color: var(--primary) !important;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }


    &_main-input {
      position: relative;

      &:focus {
        outline: none;
      }
    }
  }

  .addNewFilter {
    color: var(--primary);
    cursor: pointer;
    display: grid;
    grid-template-columns: 12px 1fr;
    align-items: center;
    grid-gap: 4px;
    padding: 5px 9px;
    border: 1px dashed #CCCCCC;
    border-radius: 3px;
    line-height: 1.125rem;
    font-size: 0.75rem;
  }

  .top10Results_container {
    padding-top: 4px;
    margin-top: 12px;

    .top10Results_label {
      color: var(--gray-600, var(--gray));
      margin-bottom: 8px;
      font-size: 0.75rem;
      line-height: 18px;
      letter-spacing: 0.02em;
    }

    .top10Results {
      display: flex;
      grid-gap: 8px;
      gap: 8px;
      flex-wrap: wrap;

      button {
        background: var(--gray-100);
      }
    }
  }

  .Suggestions {
    position: absolute;
    left: 0;
    top: 36px;
    background: var(--white);
    border-radius: 3px;
    width: 242px;
    box-shadow: 0 0 1px rgba(17, 60, 92, 0.06), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 2px rgba(0, 0, 0, 0.1);
    padding: 8px 0;

    &-item {
      padding: 8px 12px;
      font-size: #{math.div(14, 16)}rem;
      line-height: #{math.div(21, 16)}rem;
      letter-spacing: 0.02em;
      color: var(--gray);
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;

      b {
        color: black;
      }

      &.focused {
        background: var(--gray-100);
      }


    }

    &.has-more-then {
      padding-bottom: 0;
    }

    &-more-then {
      padding: 8px 12px 16px;
      font-size: #{math.div(12, 16)}rem;
      line-height: #{math.div(18, 16)}rem;
      letter-spacing: 0.02em;
      color: var(--gray);
      background: var(--gray-100);

    }
  }
}
