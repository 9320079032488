.checkboxes-list {
  max-width: 100%;

  > div {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .label {
    width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  label.custom-control-label {
    width: 100%;
    cursor: pointer;
    padding-top: 4px;
    padding-bottom: 4px;

    &:before,
    &:after {
      top: 0.3125rem;
    }

    .value {
      width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;

      .icon {
        display: block;
        float: left;
        margin-right: 8px;
        height: 1rem;
      }

      .name {
        display: block;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        ~ .count {
          margin-left: 0.3em;
        }
      }

      .count {
        display: block;
        float: left;
        color: var(--gray);
      }
    }
  }
  &-virtual-list {
    padding-left: 3px;
    &::-webkit-scrollbar {
      position: relative;
      width: 16px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      min-height: 10px;
      background-clip: padding-box;
      border-right: 5px solid transparent;
      border-top: 0 solid transparent;
      border-bottom: 0 solid transparent;
      border-left: 5px solid transparent;
      border-radius: 6px;
      background-color: #bdd9ec;
      cursor: pointer;
    }

    &::-webkit-scrollbar-button {
      display: block;
      height: 0;
    }
  }
}
