.FilterPanel {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 1rem;

  &__header {
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
  }

  &__search {
    button {
      height: 100%;
    }
  }

  &__filtersContainer {
    padding-left: 0;
    border: none;
    box-shadow: none;
  }

  .hidden {
    display: none;
  }

  & .filterBtn {
    white-space: nowrap;
  }
}
