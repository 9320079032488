.UserLogs {
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
  }
  &__filters {
    border: 0;
    box-shadow: none;
    padding: 0 8px;
  }
  &__downloadButton {
    white-space: nowrap;
  }
}
