.filter-numeric-range{
  grid-column-start: 1;
  grid-column-end: 3;

  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: 17px;
  grid-gap: 17px;

  ::-webkit-inner-spin-button{
    display: none;
  }

  &-col{

    position: relative;

    &:nth-child(1){
      grid-column: 1/2;
    }
    &:nth-child(2){
      grid-column: 2/2;
    }

    .filter-numeric-custom-label{
      position: absolute;
      top: 50%;
      font-size: 0.75rem;
      transform: translateY(-50%);
      left: 0.5rem;
    }
  }
}

.clear-filter-numeric-custom{
  grid-row: 3;
  grid-column: 1;
  padding: 0 1rem;
  width: 100px;
}
