.MultipleFilter {
  & > div:first-child {
    padding: 16px 16px 8px 16px;
  }
  & > div:not(:first-child) {
    padding: 0px 16px 8px 16px;
  }
  & > div:last-child {
    padding: 0px 16px 16px 16px;
  }
}
