.AppErrorBoundary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  height: 100%;

  color: var(--grey-800);

  border-radius: 8px;

  &:global(.justui__icon-wrapper) {
    display: flex;
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
  }

  &__description {
    font-size: 16px;
  }
}
