@mixin webkitScroll() {
  &::-webkit-scrollbar {
    position: relative;
    width: 16px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 10px;
    background-clip: padding-box;
    border-right: 5px solid transparent;
    border-top: 0 solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 5px solid transparent;
    border-radius: 6px;
    background-color: #bdd9ec;
    cursor: pointer;
  }

  &::-webkit-scrollbar-button {
    display: block;
    height: 0;
  }
}

.BaseModalFilter_container {
  position: relative;
  max-width: 50vw;

  padding: 1rem;

  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: minmax(20px, auto) min-content min-content;
  gap: 1rem;

  &_multiple {
    display: block;
  }

  .ReactSelect.sm .ReactSelectPrefix__value-container {
    line-height: 26px;
  }
  @media (max-width: 700px) {
    max-width: 100vw;
  }

  & > label {
    margin-bottom: 0;

    grid-column: 1/3;
    grid-row: 1/2;
    max-width: 90%;

    font-size: 14px;
    font-weight: bold;
    color: var(--gray-800);
  }

  &_helpLink {
    margin-top: 3px;
    justify-self: end;
    grid-column: 2/3;
    grid-row: 1/2;
  }

  &_buttonContainer {
    grid-row: 3/3;
    grid-column: 2/2;
    justify-self: end;
    align-self: center;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & > button:last-child {
      margin-left: 0.5rem;
    }
  }

  &_inputsContainer {
    grid-row: 2/2;
    grid-column: 1/3;
    width: 100%;

    & > * {
      width: 100%;
    }
    .CompositeFilterItem {
      .btn {
        line-height: 1.15;
      }
    }

    .description {
      font-size: 0.75rem;
      line-height: 1.15rem;
      color: var(--gray-600, var(--gray));
      letter-spacing: 0.02em;
      margin-bottom: 12px;
      white-space: break-spaces;
    }
  }

  &_inputsTreeContainer {
    border: 1px solid #cccccc;
    border-radius: 3px;
  }

  &_inputsTreeContainerHide {
    display: none;
  }

  &_inputCustomGroup {
    position: relative;
    margin-top: 10px;
    input {
      padding-right: 30px;
    }
  }

  &_registerIcon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 1px;
    top: 1px;
    height: 28px;
    border-bottom-right-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    width: 28px;
    svg {
      color: var(--gray);
    }
  }
  &_registerIconSelected {
    background-color: var(--primary-100);
    svg {
      color: var(--primary);
    }
  }

  &_inputWithEnterPadding {
    padding-right: 3rem;
  }
  &_dropdownOption {
    border-bottom: 1px solid #ededed;
    overflow: hidden;
    text-overflow: ellipsis;
    &:first-child {
      border-top: 1px solid #ededed;
    }
  }
  &_inputWithEnter {
    position: relative;

    &:after {
      content: 'Enter';
      position: absolute;

      top: 0;
      right: 0;

      width: auto;
      height: 38px;

      padding: 0 8px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      color: var(--gray-500);
      font-size: 0.75rem;
    }
  }

  &_iconContainer {
    & > svg {
      height: 16px;
      width: 16px;
      vertical-align: baseline;
    }
  }
  &_fullWidth {
    grid-column: 1/-1;

    & > * {
      width: 100%;
    }
  }
  &_fieldOptionsContainer {
    &:not(:first-child) {
      margin-top: 8px;
    }
    max-height: 350px;
    padding-left: 5px;
    overflow: auto;
    position: relative;
    max-width: 600px;
    @media (max-width: 600px) {
      max-width: 100vw;
    }

    transform: translateX(-5px);
    &::-webkit-scrollbar {
      min-width: 6px;
      width: 6px;
      padding-right: 2px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      min-height: 60px;
      height: 60px;
      border-radius: 100px;
      background-color: #bdd9ec;
      opacity: 0.5;
      border: none;
    }

    &::-webkit-scrollbar-button {
      display: block;
      height: 0;
    }
    span[class='value'] {
      display: flex;
    }
    div[class='custom-checkbox custom-checkbox-sm custom-control'] {
      margin: 6px 0;
    }
    span[class='value'] {
      max-width: 100%;
      position: relative;
      span[class='name'] {
        max-width: calc(100% - 2rem);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &_fieldOption {
    height: 24px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & > span {
      max-width: calc(100% - 24px);
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 8px;
      color: var(--gray-950);
    }

    & > button {
      height: 24px;
      width: 24px;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      padding: 0.25rem;

      border: none;
    }
  }
  &_treeInputContainer {
    padding: 8px;
    border-bottom: 1px solid #cccccc;

    svg {
      width: 0.725rem;
      height: 0.725rem;
      font-size: 0.725rem;
    }

    input {
      border-radius: 4px !important;
      background: var(--gray-100);
      color: var(--gray-600);
      border-color: var(--gray-200);
      padding-left: 32px !important;
    }

    .widePrepend {
      width: 32px !important;
      margin-right: -32px !important;
    }
  }
  &_treeContainer {
    min-height: calc(24px * 7);
    max-height: calc(24px * 7);
    overflow: auto;

    .justuiTree > div:nth-child(1) {
      @include webkitScroll();
    }
  }

  &_selectTreeOptionsContainer {
    margin-bottom: 0.5rem;
  }
  &_textMuted {
    font-size: 0.75rem;
    margin-top: 9px;
  }
}

.BaseModalFilter_container_comparisonContainer ~ .BaseModalFilter_container_inputsContainer {
  margin-top: 8px;
}

.BaseModalFilter_container_wrapper_right {
  left: initial !important;
  right: 0 !important;
}
