.UserLogsTable {
  & :global(tr th) {
    border-top: none;
    color: var(--gray-800);
  }
  & :global(tr:last-of-type) {
    border-bottom: none;
  }

  th,
  td {
    padding: 1rem 0.5rem !important;
  }
  tbody {
    td {
      vertical-align: middle;
      overflow-wrap: anywhere;
    }
  }

  &__paginationContainer {
    display: flex;
    justify-content: flex-end;
  }
}
