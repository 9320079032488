.rdrInputDateContainer {
  width: auto;
}
.FilterModal {
  overflow: visible;
  .popper-wrapper {
    overflow: visible;
  }
}
.rdrDateInputAndPickerSingleDateMode {
  .rdrCustomContainerForDatePicker.rdrIntegratedCalendar .rdrInputDateContainer {
    width: 280px;
  }
  .rdrDatePickerSingleMode {
    grid-template-columns: 280px !important;

    .rdrMonth {
      width: 280px !important;

      .rdrDay, .rdrWeekDay {
        width: 2.5rem !important;
      }
    }
  }
}
