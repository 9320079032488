.filtersPanelContainer {
  position: sticky;
  top: 0;
  width: 100%;
  min-height: 48px;

  z-index: 2;

  padding: 0.5rem 1.5rem;

  background: var(--white);

  box-shadow: 0 0 1px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(12, 43, 66, 0.06), 0 0 1px rgba(17, 60, 92, 0.06);
  border-radius: 4px;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  gap: 4px;

  & form:only-child {
    grid-column: 1 / -1;
  }

  &.withoutBorder {
    padding: 0;
    margin: 0;
  }

  &.withBottomActions {
    grid-template-rows: auto auto;
    .bottomActions {
      grid-row: 2/2;
      grid-column: 1/2;
    }
  }
  & > .label {
    color: var(--gray);
    font-size: 0.75rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .filtersPanel_filtersContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
}

.filtersPanel_filterContainer {
  max-width: 100px;
  width: 100px;
  &.opened {
    max-width: 200px;
    width: 200px;
  }
  &.withFilter {
    max-width: 800px;
    width: auto;
  }
  &.hidden {
    display: none;
    opacity: 0;
  }
}
.filtersPanel_filtersContainer_type_COMPOSITE_FILTER {
  .FilterModal > .popper-wrapper {
    min-width: 528px !important;
    .CompositeFilter {
      min-width: calc(528px - 2rem) !important;
    }
  }
}
.FilterModal .popper-wrapper {
  overflow: visible !important;
}
